.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.portfolio-box::before,
.portfolio-box::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    transition: .5s;
    z-index: 1;
    background: rgba(4, 15, 40, .7);
}

.portfolio-box::before {
    left: 0;
}

.portfolio-box::after {
    right: 0;
}

.portfolio-item:hover .portfolio-box::before {
    left: 0;
    width: 50%;
}

.portfolio-item:hover .portfolio-box::after {
    right: 0;
    width: 50%;
}
.portfolio-title {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 30px;
  right: 30px;
  left: 30px;
  bottom: -60px;
  background: #FFFFFF;
  z-index: 3;
}
.service-item .service-icon {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item .service-icon i {
  transition: .2s;
}

.service-item:hover .service-icon i {
  font-size: 60px;
}
.service-item .service-icon {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item .service-icon i {
  transition: .2s;
}

.service-item:hover .service-icon i {
  font-size: 60px;
}
.blog .blog-item .blog-img {
  position: relative;
}

.blog .blog-item .blog-img .blog-info {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  background: rgba(255, 255, 255, .2);
  color: var(--bs-white);
}

.blog .blog-item .blog-img .blog-img-inner {
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.blog .blog-item .blog-img .blog-img-inner .blog-icon {
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.blog .blog-item .blog-img .blog-img-inner .blog-icon a {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.blog .blog-item .blog-img:hover .blog-img-inner .blog-icon a {
  opacity: 1;
}

.blog .blog-item .blog-img:hover .blog-img-inner .blog-icon {
  height: 100%;
  background: rgba(19, 53, 123, .6);
  opacity: 1;
}

.blog .blog-item .blog-img .blog-img-inner {
  overflow: hidden;
}

.blog .blog-item .blog-img .blog-img-inner img {
  transition: 0.5s;
}

.blog .blog-item .blog-img:hover .blog-img-inner img {
  transform: scale(1.2);
}
/*** Blog End ***/

/*** Testimonial Start ***/
.testimonial .testimonial-carousel {
  position: relative;
}


.page-headerr {
    background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(https://th.bing.com/th/id/R.2ed96b15481a656c4815f7040c4bfddb?rik=0a98pw70xo7hBg&riu=http%3a%2f%2fkardiaholdings.co.zw%2fbulawayo%2fimages%2fmain-slider%2fimage-2.jpg&ehk=LYj1t3h1Xqmx46swrbTQeHEk6iJlrESGM8Bl6Bp4wCE%3d&risl=&pid=ImgRaw&r=0) center center no-repeat;
    background-size: cover;
    color: white;
    padding-top: 20px;
    
}

.session-title {
  padding: 30px;
  margin: 0px;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .session-title {
      padding: 5px; } }
  .session-title h2 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px; }
  .session-title p {
    max-width: 850px;
    text-align: center;
    float: none;
    margin: auto;
    font-size: .9rem;
    margin-top: 6px; }
  .session-title span {
    float: right;
    font-style: italic; }




    .chat-search-box {
      -webkit-border-radius: 3px 0 0 0;
      -moz-border-radius: 3px 0 0 0;
      border-radius: 3px 0 0 0;
      padding: .75rem 1rem;
  }
  
  .chat-search-box .input-group .form-control {
      -webkit-border-radius: 2px 0 0 2px;
      -moz-border-radius: 2px 0 0 2px;
      border-radius: 2px 0 0 2px;
      border-right: 0;
  }
  
  .chat-search-box .input-group .form-control:focus {
      border-right: 0;
  }
  
  .chat-search-box .input-group .input-group-btn .btn {
      -webkit-border-radius: 0 2px 2px 0;
      -moz-border-radius: 0 2px 2px 0;
      border-radius: 0 2px 2px 0;
      margin: 0;
  }
  
  .chat-search-box .input-group .input-group-btn .btn i {
      font-size: 1.2rem;
      line-height: 100%;
      vertical-align: middle;
  }
  
  @media (max-width: 767px) {
      .chat-search-box {
          display: none;
      }
      
  }
  
  
  /************************************************
    ************************************************
                    Users Container
    ************************************************
  ************************************************/
  
  .users-container {
      position: relative;
      padding: 1rem 0;
      border-right: 1px solid #e6ecf3;
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: black;
      color: white;
  }
  
  
  /************************************************
    ************************************************
                        Users
    ************************************************
  ************************************************/
  
  .users {
      padding: 0;
      margin-left: 20px;
      
    
  }
  
  .users .person {
    
      position: relative;
      width: 95%;
      padding: 10px 1px;
      cursor: pointer;
     font-weight: bolder;
      border-bottom: 1px solid #f0f4f8;
  }
  .del{
    display: flex;
      justify-content: end;
  
  }
  .users .person:hover {
      background-color: pink;
      /* Fallback Color */
      background-image: -webkit-gradient(linear, left top, left bottom, from(#e9eff5), to(#ffffff));
      /* Saf4+, Chrome */
      background-image: -webkit-linear-gradient(right, #e9eff5, #ffffff);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background-image: -moz-linear-gradient(right, #e9eff5, #ffffff);
      /* FF3.6 */
      background-image: -ms-linear-gradient(right, #e9eff5, #ffffff);
      /* IE10 */
      background-image: -o-linear-gradient(right, #e9eff5, #ffffff);
      /* Opera 11.10+ */
      background-image: linear-gradient(right, #e9eff5, #ffffff);
  }
  
  .users .person.active-user {
      background-color: #ffffff;
      /* Fallback Color */
      background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f9fb), to(#ffffff));
      /* Saf4+, Chrome */
      background-image: -webkit-linear-gradient(right, #f7f9fb, #ffffff);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background-image: -moz-linear-gradient(right, #f7f9fb, #ffffff);
      /* FF3.6 */
      background-image: -ms-linear-gradient(right, #f7f9fb, #ffffff);
      /* IE10 */
      background-image: -o-linear-gradient(right, #f7f9fb, #ffffff);
      /* Opera 11.10+ */
      background-image: linear-gradient(right, #f7f9fb, #ffffff);
  }
  
  .users .person:last-child {
      border-bottom: 0;
     
  }
  
  .users .person .user {
      display: inline-block;
      position: relative;
      margin-right: 10px;
    
  }
  
  .users .person .user img {
      width: 48px;
      height: 48px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
  }
  
  .users .person .user .status {
      width: 10px;
      height: 10px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      background: #0a4486;
      position: absolute;
      top: 0;
      right: 0;
  }
  
  .users .person .user .status.online {
      background: #9ec94a;
  }
  
  .users .person .user .status.offline {
      background: #c4d2e2;
  }
  
  .users .person .user .status.away {
      background: #f9be52;
  }
  
  .users .person .user .status.busy {
      background: #fd7274;
  }
  
  .users .person p.name-time {
      font-weight: 600;
      font-size: .85rem;
      display: inline-block;
  }
  
  .users .person p.name-time .time {
      font-weight: 400;
      font-size: .7rem;
      text-align: right;
      color: #8796af;
  }
  
  @media (max-width: 767px) {
      .users .person .user img {
          width: 30px;
          height: 30px;
      }
      .users .person p.name-time {
          display: none;
      }
      .users .person p.name-time .time {
          display: none;
      }
      
  }
  
  
  /************************************************
    ************************************************
                    Chat right side
    ************************************************
  ************************************************/
  
  .selected-user {
      width: 100%;
      padding: 0 15px;
      min-height: 64px;
      line-height: 64px;
      border-bottom: 1px solid #e6ecf3;
      -webkit-border-radius: 0 3px 0 0;
      -moz-border-radius: 0 3px 0 0;
      border-radius: 0 3px 0 0;
  }
  
  .selected-user span {
      line-height: 100%;
  }
  
  .selected-user span.name {
      font-weight: 700;
  }
  
  .chat-container {
      position: relative;
      padding: 1rem;
  }
  
  .chat-container li.chat-left,
  .chat-container li.chat-right {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 40px;
  }
  
  .chat-container li img {
      width: 48px;
      height: 48px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
  }
  
  .chat-container li .chat-avatar {
      margin-right: 20px;
  }
  
  .chat-container li.chat-right {
      justify-content: flex-end;
  }
  
  .chat-container li.chat-right > .chat-avatar {
      margin-left: 20px;
      margin-right: 0;
  }
  
  .chat-container li .chat-name {
      font-size: .75rem;
      color: #999999;
      text-align: center;
  }
  
  .chat-container li .chat-text {
      padding: .4rem 1rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background: #ffffff;
      font-weight: 300;
      line-height: 150%;
      position: relative;
      width: 65%;
  }
  
  .chat-container li .chat-text:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 10px;
      left: -20px;
      border: 10px solid;
      border-color: transparent #ffffff transparent transparent;
  }
  
  .chat-container li.chat-right > .chat-text {
      text-align: left;
  }
  
  .chat-container li.chat-right > .chat-text:before {
      right: -20px;
      border-color: transparent transparent transparent #ffffff;
      left: inherit;
  }
  
  .chat-container li .chat-hour {
      padding: 0;
      margin-bottom: 10px;
      font-size: .75rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 15px;
  }
  
  .chat-container li .chat-hour > span {
      font-size: 16px;
      color: #9ec94a;
  }
  
  .chat-container li.chat-right > .chat-hour {
      margin: 0 15px 0 0;
  }
  
  
  @media (max-width: 767px) {
    .chat-container li .chat-text {
      padding: .4rem 1rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background: #ffffff;
      font-weight: 300;
      line-height: 150%;
      position: relative;
width: 100%;
    
  }
      .chat-container li.chat-left,
      .chat-container li.chat-right {
          flex-direction: column;
          margin-bottom: 30px;
      }
      .del{
      display: flex;
      justify-content: end;
      }
      .chat-container li img {
          width: 32px;
          height: 32px;
      }
      .chat-container li.chat-left .chat-avatar {
          margin: 0 0 5px 0;
          display: flex;
          align-items: center;
      }
      .chat-container li.chat-left .chat-hour {
          justify-content: flex-end;
      }
      .chat-container li.chat-left .chat-name {
          margin-left: 5px;
      }
      .chat-container li.chat-right .chat-avatar {
          order: -1;
          margin: 0 0 5px 0;
          align-items: center;
          display: flex;
          justify-content: right;
          flex-direction: row-reverse;
      }
      .chat-container li.chat-right .chat-hour {
          justify-content: flex-start;
          order: 2;
      }
      .chat-container li.chat-right .chat-name {
          margin-right: 5px;
      }
      .chat-container li .chat-text {
          font-size: .8rem;
      }
  }
  
  .chat-form {
      padding: 15px;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      border-top: 1px solid white;
  }
  
  ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
  }
  .card {
      border: 0;
      background: #f4f5fb;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      margin-bottom: 2rem;
      box-shadow: none; 
      padding:0 20px ;
      height: 100%;
  }.auth .brand-logo {
    margin-bottom: 2rem;
  }
  .auth .brand-logo img {
    width: 100%;
 
  }
  .section {
    padding: 100px 0;
  }
  
  .section-sm {
    padding: 70px 0;
  }
  
  .section-title {
    margin-bottom: 70px;
  }
  
  .section-title .title {
    font-size: 50px;
    line-height: 50px;
  }
  
  .section-title p {
    color: #666;
    font-family: "Poppins", sans-serif;
  }
  .comments-section {
    margin-top: 35px;
  }
  .blog-item-meta span {
    margin: 6px 0px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .blog-item-content h3 {
    line-height: 36px;
  }
  .blog-item-content h3 a {
    transition: all .4s ease 0s;
  }
  .blog-item-content h3 a:hover {
    color: #f75757 !important;
  }

  
.blog-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.blog-item-content h3 {
  line-height: 36px;
}

.blog-item-content h3 a {
  transition: all .4s ease 0s;
}

.blog-item-content h3 a:hover {
  color: #f75757 !important;
}
.blog-item-meta span {
  margin: 6px 0px;
}
.blog-item-meta span {
  display: block;
  margin: 6px 0px;
}
.blog-item-meta span {
  display: block;
  margin: 6px 0px;
}
.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: #f75757;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #f75757;
  border: 1px solid #f75757;
}
.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}
.nanaa{
 width: 120px;

}
.pic{
  width: 100%;
  height: 50%;
}
.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}
.image404{
  width: 100%;
  height: 400px;
}
.cascading-right {
  margin-right: -50px;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 0;
  }
}.service-item .service-icon {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.service-item .service-icon i {
  transition: .2s;
}

.service-item:hover .service-icon i {
  font-size: 60px;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  background: #ffffff;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #fdbe33;
  color: #121518;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  color: #fdbe33;
  background: #121518;
}

.back-to-top i {
  padding-top: 10px;
}

.btn {
  transition: .3s;
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  position: relative;
  height: 90px;
  background: #fdbe33;
}

.top-bar .logo {
  padding: 15px 0;
  text-align: left;
  overflow: hidden;
}

.top-bar .logo h1 {
  margin: 0;
  color: #030f27;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
}

.top-bar .logo img {
  max-width: 100%;
  max-height: 72px;
}

.top-bar .top-bar-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .top-bar-icon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar .top-bar-icon [class^="flaticon-"]::before {
  margin: 0;
  color: #030f27;
  font-size: 40px;
}

.top-bar .top-bar-text {
  padding-left: 15px;
}

.top-bar .top-bar-text h3 {
  margin: 0 0 5px 0;
  color: #030f27;
  font-size: 16px;
  font-weight: 400;
}

.top-bar .top-bar-text p {
  margin: 0;
  color: #030f27;
  font-size: 13px;
  font-weight: 400;
}

@media (min-width: 992px) {
  .top-bar {
      padding: 0 60px;
  }
}

@media (max-width: 991.98px) {
  .top-bar .logo {
      text-align: center;
  }
}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.nav-bar {
  position: relative;
  background: #fdbe33;
  transition: .3s;
}

.nav-bar .container-fluid {
  padding: 0;
}

.nav-bar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1366px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
  z-index: 999;
}

.nav-bar .navbar {
  height: 100%;
  background: #030f27 !important;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 10px 8px 10px;
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fdbe33;
  transition: none;
}

.nav-bar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

.nav-bar .btn {
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 0;
}

.nav-bar .btn:hover {
  color: #030f27;
  background: #fdbe33;
  border-color: #fdbe33;
}

@media (min-width: 992px) {
  .nav-bar {
      padding: 0 75px;
  }
  
  .nav-bar.nav-sticky {
      padding: 0;
  }
  
  .nav-bar .navbar {
      padding: 20px;
  }
  
  .nav-bar .navbar-brand {
      display: none;
  }
  
  .nav-bar a.nav-link {
      padding: 8px 15px;
      font-size: 15px;
      text-transform: uppercase;
  }
}

@media (max-width: 991.98px) {
  .nav-bar .navbar {
      padding: 15px;
  }
  
  .nav-bar a.nav-link {
      padding: 5px;
  }
  
  .nav-bar .dropdown-menu {
      box-shadow: none;
  }
  
  .nav-bar .btn {
      display: none;
  }
}.carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.carouselt {
  position: relative;
  width: 100%;
  height: calc(100vh - 90px);
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.carousel .carousel-inner,
.carousel .carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 1;
}

.carousel .carousel-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 170px);
  min-height: 400px;
}

.carousel .carousel-caption p {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.carousel .carousel-caption h1 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 35px;
}

.carousel .carousel-caption .btn {
  padding: 15px 35px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 0;
  transition: .3s;
}

.carousel .carousel-caption .btn:hover {
  color: #030f27;
  background: #fdbe33;
  border-color: #fdbe33;
}

@media (max-width: 767.98px) {
  .carousel .carousel-caption h1 {
      font-size: 40px;
      font-weight: 700;
  }
  
  .carousel .carousel-caption p {
      font-size: 20px;
  }
  
  .carousel .carousel-caption .btn {
      padding: 12px 30px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
  }
}

@media (max-width: 575.98px) {
  .carousel .carousel-caption h1 {
      font-size: 30px;
      font-weight: 500;
  }
  
  .carousel .carousel-caption p {
      font-size: 16px;
  }
  
  .carousel .carousel-caption .btn {
      padding: 10px 25px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
  }
}

.carousel .animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}







.feature {
  position: relative;
  margin-bottom: 45px;
}

.feature .col-md-12 {
  background: #030f27;
}
  
.feature .col-md-12:nth-child(2n) {
  color: #030f27;
  background: #fdbe33;
}

.feature .feature-item {
  min-height: 250px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.feature .feature-icon {
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature .feature-icon::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  top: -20px;
  left: -10px;
  border: 2px dotted #ffffff;
  border-radius: 60px;
  z-index: 1;
}

.feature .feature-icon::after {
  position: absolute;
  content: "";
  width: 79px;
  height: 79px;
  top: -18px;
  left: -9px;
  background: #030f27;
  border-radius: 60px;
  z-index: 2;
}

.feature .col-md-12:nth-child(2n) .feature-icon::after {
  background: #fdbe33;
}

.feature .feature-icon [class^="flaticon-"]::before {
  position: relative;
  margin: 0;
  color: #fdbe33;
  font-size: 60px;
  line-height: 60px;
  z-index: 3;
}

.feature .feature-text {
  padding-left: 30px;
}

.feature .feature-text h3 {
  margin: 0 0 10px 0;
  color: #fdbe33;
  font-size: 25px;
  font-weight: 600;
}

.feature .feature-text p {
  margin: 0;
  color: #fdbe33;
  font-size: 18px;
  font-weight: 400;
}

.feature .col-md-12:nth-child(2n) [class^="flaticon-"]::before,
.feature .col-md-12:nth-child(2n) h3,
.feature .col-md-12:nth-child(2n) p {
  color: #030f27;
}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {
  position: relative;
  height: 100%;
}

.about .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about .about-text p {
  font-size: 16px;
}

.about .about-text a.btn {
  position: relative;
  margin-top: 15px;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #030f27;
  border-radius: 0;
  background: #fdbe33;
  transition: .3s;
}

.about .about-text a.btn:hover {
  color: #fdbe33;
  background: #030f27;
}

@media (max-width: 767.98px) {
  .about .about-img {
      margin-bottom: 30px;
      height: auto;
  }
}


/*******************************/
/********** Fact CSS ***********/
/*******************************/
.fact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.fact .col-6 {
  display: flex;
  align-items: flex-start;
}

.fact .fact-icon {
  position: relative;
  margin: 7px 15px 0 15px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fact .fact-icon [class^="flaticon-"]::before {
  margin: 0;
  font-size: 60px;
  line-height: 60px;
  background-image: linear-gradient(#ffffff, #fdbe33);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fact .fact-right .fact-icon [class^="flaticon-"]::before {
  background-image: linear-gradient(#ffffff, #030f27);
}

.fact .fact-left,
.fact .fact-right {
  padding-top: 60px;
  padding-bottom: 60px;
}

.fact .fact-text h2 {
  font-size: 35px;
  font-weight: 700;
}

.fact .fact-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.fact .fact-left {
  color: #fdbe33;
  background: #030f27;
}

.fact .fact-right {
  color: #030f27;
  background: #fdbe33;
}

.fact .fact-left h2 {
  color: #fdbe33;
}


/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.service .service-img {
  position: relative;
  overflow: hidden;
}

.service .service-img img {
  width: 100%;
}

.service .service-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 15, 39, .7);
  transition: .5s;
  opacity: 0;
}

.service .service-item:hover .service-overlay {
  opacity: 1;
}

.service .service-overlay p {
  margin: 0;
  color: #ffffff;
}

.service .service-text {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service .service-text h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 20px;
  font-weight: 700;
  color: #fdbe33;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service .service-item a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #fdbe33;
  border-radius: 0;
  transition: .3s;
}

.service .service-item:hover a.btn {
  color: #ffffff;
}


/*******************************/
/********** Video CSS **********/
/*******************************/
.video {
  position: relative;
  margin: 45px 0;
  height: 100%;
  min-height: 500px;;
 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video .btn-play {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #fdbe33;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #fdbe33;
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play:hover:after {
  background-color: darken(#fdbe33, 10%);
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #030f27;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
0% {
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  opacity: 1;
}
100% {
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
  opacity: 0;
}
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}


/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.team .team-img {
  position: relative;
}

.team .team-img img {
  width: 100%;
}

.team .team-text {
  position: relative;
  padding: 25px 15px;
  text-align: center;
  background: #030f27;
  transition: .5s;
}

.team .team-text h2 {
  font-size: 20px;
  font-weight: 600;
  color: #fdbe33;
  transition: .5s;
}

.team .team-text p {
  margin: 0;
  color: #ffffff;
}

.team .team-item:hover .team-text {
  background: #fdbe33;
}

.team .team-item:hover .team-text h2 {
  color: #030f27;
  letter-spacing: 1px;
}

.team .team-social {
  position: absolute;
  width: 100px;
  top: 0;
  left: -50px;
  display: flex;
  flex-direction: column;
  font-size: 0;
}

.team .team-social a {
  position: relative;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ffffff;
}

.team .team-item:hover .team-social a:first-child {
  background: #00acee;
  left: 50px;
  transition: .3s 0s;
}

.team .team-item:hover .team-social a:nth-child(2) {
  background: #3b5998;
  left: 50px;
  transition: .3s .1s;
}

.team .team-item:hover .team-social a:nth-child(3) {
  background: #0e76a8;
  left: 50px;
  transition: .3s .2s;
}

.team .team-item:hover .team-social a:nth-child(4) {
  background: #3f729b;
  left: 50px;
  transition: .3s .3s;
}


/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.faqs .row {
  position: relative;
}

.faqs .row::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: calc(50% - .5px);
  background: #fdbe33;
}

.faqs #accordion-1 {
  padding-right: 15px;
}

.faqs #accordion-2 {
  padding-left: 15px;
}

@media(max-width: 767.98px) {
  .faqs .row::after {
      display: none;
  }
  
  .faqs #accordion-1,
  .faqs #accordion-2 {
      padding: 0;
  }
  
  .faqs #accordion-2 {
      padding-top: 15px;
  }
}
.faqs .card {
  margin-bottom: 15px;
  border: none;
  border-radius: 0;
}

.faqs .card:last-child {
  margin-bottom: 0;
}

.faqs .card-header {
  padding: 0;
  border: none;
  background: #ffffff;
}

.faqs .card-header a {
  display: block;
  padding: 10px 25px;
  width: 100%;
  color: #121518;
  font-size: 16px;
  line-height: 40px;
  border: 1px solid rgba(0, 0, 0, .1);
  transition: .5s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"] {
  background: #fdbe33;
}

.faqs .card-header [data-toggle="collapse"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f067";
  float: right;
  color: #fdbe33;
  font-size: 12px;
  font-weight: 900;
  transition: .5s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f068";
  float: right;
  color: #030f27;
  font-size: 12px;
  font-weight: 900;
  transition: .5s;
}

.faqs .card-body {
  padding: 20px 25px;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-top: none;
}
.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.blog .blog-img {
  position: relative;
  overflow: hidden;
}

.blog .blog-img img {
  width: 100%;
}

.blog .blog-title {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog .blog-title h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 18px;
  font-weight: 700;
  color: #fdbe33;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog .blog-title a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #fdbe33;
  border-radius: 0;
  transition: .3s;
}

.blog .blog-item:hover a.btn {
  color: #ffffff;
}

.blog .blog-meta {
  position: relative;
  padding: 25px 0 10px 0;
  background: #f3f6ff;
}

.blog .blog-meta::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 1px;
  left: calc(50% - 50px);
  bottom: 0;
  background: #fdbe33;
}

.blog .blog-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  color: #666666;
}

.blog .blog-meta p a {
  margin-left: 5px;
  font-style: normal;
}

.blog .blog-text {
  padding: 10px 25px 25px 25px;
  background: #f3f6ff;
}

.blog .blog-text p {
  margin: 0;
  font-size: 16px;
}

.blog .pagination .page-link {
  color: #030f27;
  border-radius: 0;
  border-color: #fdbe33;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #fdbe33;
  background: #030f27;
}

.blog .pagination .disabled .page-link {
  color: #999999;
}


/*******************************/
/******* Single Post CSS *******/
/*******************************/
.single {
  position: relative;
  padding: 45px 0;
}

.single .single-content {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single .single-content img {
  margin-bottom: 20px;
  width: 100%;
}

.single .single-tags {
  margin: -5px -5px 41px -5px;
  font-size: 0;
}

.single .single-tags a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #dddddd;
}

.single .single-tags a:hover {
  color: #fdbe33;
  background: #030f27;
}

.single .single-bio {
  margin-bottom: 45px;
  padding: 30px;
  background: #f3f6ff;
  display: flex;
}

.single .single-bio-img {
  width: 100%;
  max-width: 100px;
}

.single .single-bio-img img {
  width: 100%;
}

.single .single-bio-text {
  padding-left: 30px;
}

.single .single-bio-text h3 {
  font-size: 20px;
  font-weight: 700;
}

.single .single-bio-text p {
  margin: 0;
}

.single .single-related {
  margin-bottom: 45px;
}

.single .single-related h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .related-slider {
  position: relative;
  margin: 0 -15px;
  width: calc(100% + 30px);
}

.single .related-slider .post-item {
  margin: 0 15px;
}

.single .post-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.single .post-item .post-img {
  width: 100%;
  max-width: 80px;
}

.single .post-item .post-img img {
  width: 100%;
}

.single .post-item .post-text {
  padding-left: 15px;
}

.single .post-item .post-text a {
  font-size: 16px;
  font-weight: 400;
}

.single .post-item .post-meta {
  display: flex;
  margin-top: 8px;
}

.single .post-item .post-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}

.single .post-item .post-meta p a {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
}

.single .related-slider .owl-nav {
  position: absolute;
  width: 90px;
  top: -55px;
  right: 15px;
  display: flex;
}

.single .related-slider .owl-nav .owl-prev,
.single .related-slider .owl-nav .owl-next {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030f27;
  background: #fdbe33;
  font-size: 16px;
  transition: .3s;
}

.single .related-slider .owl-nav .owl-prev:hover,
.single .related-slider .owl-nav .owl-next:hover {
  color: #fdbe33;
  background: #030f27;
}

.single .single-comment {
  position: relative;
  margin-bottom: 45px;
}

.single .single-comment h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-list {
  list-style: none;
  padding: 0;
}

.single .comment-child {
  list-style: none;
}

.single .comment-body {
  display: flex;
  margin-bottom: 30px;
}

.single .comment-img {
  width: 60px;
}

.single .comment-img img {
  width: 100%;
}

.single .comment-text {
  padding-left: 15px;
  width: calc(100% - 60px);
}

.single .comment-text h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}

.single .comment-text span {
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}

.single .comment-text .btn {
  padding: 3px 10px;
  font-size: 14px;
  color: #030f27;
  background: #dddddd;
  border-radius: 0;
}

.single .comment-text .btn:hover {
  background: #fdbe33;
}

.single .comment-form {
  position: relative;
}

.single .comment-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-form form {
  padding: 30px;
  background: #f3f6ff;
}

.single .comment-form form .form-group:last-child {
  margin: 0;
}

.single .comment-form input,
.single .comment-form textarea {
  border-radius: 0;
}

.single .comment-form .btn {
  padding: 15px 30px;
  color: #030f27;
  background: #fdbe33;
}

.single .comment-form .btn:hover {
  color: #fdbe33;
  background: #030f27;
}


/**********************************/
/*********** Sidebar CSS **********/
/**********************************/
.sidebar {
  position: relative;
  width: 100%;
}

@media(max-width: 991.98px) {
  .sidebar {
      margin-top: 45px;
  }
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

.sidebar .sidebar-widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
  font-size: 30px;
  font-weight: 700;
}

.sidebar .sidebar-widget .widget-title::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #fdbe33;
}

.sidebar .sidebar-widget .search-widget {
  position: relative;
}

.sidebar .search-widget input {
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 0;
}

.sidebar .search-widget input:focus {
  box-shadow: none;
}

.sidebar .search-widget .btn {
  position: absolute;
  top: 6px;
  right: 15px;
  height: 40px;
  padding: 0;
  font-size: 25px;
  color: #fdbe33;
  background: none;
  border-radius: 0;
  border: none;
  transition: .3s;
}

.sidebar .search-widget .btn:hover {
  color: #030f27;
}

.sidebar .sidebar-widget .recent-post {
  position: relative;
}

.sidebar .sidebar-widget .tab-post {
  position: relative;
}

.sidebar .tab-post .nav.nav-pills .nav-link {
  color: #fdbe33;
  background: #030f27;
  border-radius: 0;
}

.sidebar .tab-post .nav.nav-pills .nav-link:hover,
.sidebar .tab-post .nav.nav-pills .nav-link.active {
  color: #030f27;
  background: #fdbe33;
}

.sidebar .tab-post .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.sidebar .tab-post .tab-content .container {
  padding: 0;
}

.sidebar .sidebar-widget .category-widget {
  position: relative;
}

.sidebar .category-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .category-widget ul li {
  margin: 0 0 12px 22px; 
}

.sidebar .category-widget ul li:last-child {
  margin-bottom: 0; 
}

.sidebar .category-widget ul li a {
  display: inline-block;
  line-height: 23px;
}

.sidebar .category-widget ul li::before {
  position: absolute;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fdbe33;
  left: 1px;
}

.sidebar .category-widget ul li span {
  display: inline-block;
  float: right;
}

.sidebar .sidebar-widget .tag-widget {
  position: relative;
  margin: -5px -5px;
}

.single .tag-widget a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #dddddd;
}

.single .tag-widget a:hover {
  color: #fdbe33;
  background: #030f27;
}

.sidebar .image-widget {
  display: block;
  width: 100%;
  overflow: hidden;
}

.sidebar .image-widget img {
  max-width: 100%;
  transition: .3s;
}

.sidebar .image-widget img:hover {
  transform: scale(1.1);
}


/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.portfolio {
  position: relative;
  padding: 45px 0;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: -15px 0 25px 0;
  list-style: none;
  font-size: 0;
  text-align: center;
}

.portfolio #portfolio-flters li,
.portfolio .load-more .btn {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  padding: 8px 15px;
  color: #030f27;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0;
  background: #fdbe33;
  border: none;
  transition: .3s;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #030f27;
  color: #fdbe33;
}

.portfolio .load-more {
  text-align: center;
}

.portfolio .load-more .btn {
  padding: 15px 35px;
  font-size: 16px;
  transition: .3s;
}

.portfolio .load-more .btn:hover {
  color: #fdbe33;
  background: #030f27;
}

.portfolio .portfolio-warp {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.portfolio .portfolio-img {
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-img img {
  width: 100%;
  transition: .3s;
}

.portfolio .portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio .portfolio-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 15, 39, .7);
  transition: .5s;
  opacity: 0;
}

.portfolio .portfolio-warp:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio .portfolio-overlay p {
  margin: 0;
  color: #ffffff;
}

.portfolio .portfolio-text {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.portfolio .portfolio-text h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 20px;
  font-weight: 700;
  color: #fdbe33;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.portfolio .portfolio-warp a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #ffffff;
  border-radius: 0;
  transition: .3s;
}

.portfolio .portfolio-warp:hover a.btn {
  color: #ffffff;
  background: #fdbe33;
}


/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.contact .col-md-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact .col-md-6:first-child {
  background: #030f27;
}

.contact .col-md-6:last-child {
  background: #fdbe33;
}

.contact .contact-info {
  position: relative;
  width: 100%;
  padding: 0 15px;
}

.contact .contact-item {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid rgba(256, 256, 256, .2);
}

.contact .contact-item [class^="flaticon-"]::before {
  margin: 0;
  color: #fdbe33;
  font-size: 40px;
}

.contact .contact-text {
  position: relative;
  width: auto;
  padding-left: 20px;
}

.contact .contact-text h2 {
  color: #fdbe33;
  font-size: 20px;
  font-weight: 600;
}

.contact .contact-text p {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
}

.contact .contact-item:last-child {
  margin-bottom: 0;
}

.contact .contact-form {
  position: relative;
  padding: 0 15px;
}

.contact .contact-form input {
  color: #ffffff;
  height: 40px;
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(256, 256, 256, .4);
  background: transparent;
}

.contact .contact-form textarea {
  color: #ffffff;
  height: 185px;
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(256, 256, 256, .4);
  background: transparent;
}

.contact .contact-form input:focus,
.contact .contact-form textarea {
  box-shadow: none;
}

.contact .contact-form .form-control::placeholder {
color: #ffffff;
opacity: 1;
}

.contact .contact-form .form-control::-ms-input-placeholder {
color: #ffffff;
}

.contact .contact-form .form-control::-ms-input-placeholder {
color: #ffffff;
}

.contact .contact-form .btn {
  padding: 16px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fdbe33;
  background: #030f27;
  border: none;
  border-radius: 0;
  transition: .3s;
}

.contact .contact-form .btn:hover {
  color: #030f27;
  background: #ffffff;
}

.contact .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}


/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  position: relative;
 
  padding-top: 90px;
  background: #030f27;
  color: #ffffff;
}

.footer .footer-contact,
.footer .footer-link,
.footer .newsletter {
  position: relative;
  margin-bottom: 45px;
}

.footer h2 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #fdbe33;
}

.footer h2::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #fdbe33;
}

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
  transition: .3s;
}

.footer .footer-link a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  color: #fdbe33;
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 7px 0;
  text-align: center;
  border: 1px solid rgba(256, 256, 256, .3);
  border-radius: 60px;
  transition: .3s;
}

.footer .footer-social a i {
  font-size: 15px;
  color: #ffffff;
}

.footer .footer-social a:hover {
  background: #fdbe33;
  border-color: #fdbe33;
}

.footer .footer-social a:hover i {
  color: #030f27;
}

.footer .newsletter .form {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.footer .newsletter input {
  height: 50px;
  border: 2px solid #121518;
  border-radius: 0;
}

.footer .newsletter .btn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fdbe33;
  background: #121518;
  border-radius: 0;
  border: 2px solid #fdbe33;
  transition: .3s;
}

.footer .newsletter .btn:hover {
  color: #121518;
  background: #fdbe33;
}

.footer .footer-menu .f-menu {
  position: relative;
  padding: 15px 0;
  font-size: 0;
  text-align: center;
  border-top: 1px solid rgba(256, 256, 256, .1);
  border-bottom: 1px solid rgba(256, 256, 256, .1);
}

.footer .footer-menu .f-menu a {
  color: #ffffff;
  font-size: 16px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu .f-menu a:hover {
  color: #fdbe33;
}

.footer .footer-menu .f-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}


.footer .copyright {
  padding: 30px 15px;
}

.footer .copyright p {
  margin: 0;
  color: #ffffff;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #fdbe33;
  font-weight: 500;
  letter-spacing: 1px;
}

.footer .copyright p a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
      margin: 5px 0;
      text-align: center;
  }
}
.blog {
  padding: 50px;
  background-color: #FBFBFB; }

@media screen and (max-width: 990px) {
  .blog {
    padding: 0px 10px; } }
@media screen and (max-width: 1199px) {
  .blog .container {
    max-width: 100%; } }
.blog .blog-singe {
  box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
  border-radius: 2px;
  background-color: #FFF;
  margin-bottom: 30px; }

.blog .blog-singe .blog-img-tab {
  padding: 0px; }
  .blog .blog-singe .blog-img-tab img {
    width: 100%; }

.blog .blog-singe .blog-content-tab {
  padding: 15px; }

.blog .blog-singe .blog-content-tab h2 {
  font-size: 19px; }

.blog .blog-singe .blog-content-tab i {
  margin-right: 10px;
  font-size: 12px; }

.blog .blog-singe .blog-content-tab i small {
  margin-left: 5px;
  font-size: 100%; }

.blog .blog-singe .blog-content-tab .blog-desic {
  margin-top: 8px;
  font-size: 17px;
 
  margin-bottom: 10px; }

.blog .blog-singe .blog-content-tab a {
  color: #06b2b6;
  font-size: 12px; }

.blog .blog-singe .blog-content-tab a i {
  margin-left: 15px;
  font-size: 13px; }

.blog-row {
  margin-top: 20px; }
  .page-nav {
background-size: 100%;
padding: 68px;
text-align: center;
padding-top: 200px;
background-color: #eaeaea; }
.page-nav ul {
  float: none;
  margin: auto; }
@media screen and (max-width: 992px) {
  .page-nav {
    background-size: auto; } }
@media screen and (max-width: 767px) {
  .page-nav {
    padding-top: 200px;
    padding-bottom: 40px; } }
@media screen and (max-width: 356px) {
  .page-nav {
    padding-top: 200px;
    padding-bottom: 40px; } }
.page-nav h2 {
  font-size: 28px;
  width: 100%;
  font-weight: 700;
  color: #444; }
  @media screen and (max-width: 600px) {
    .page-nav h2 {
      font-size: 26px; } }
.page-nav ul li {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-size: .93rem;
  font-weight: 600;
  color: #444; }
  .page-nav ul li i {
    width: 30px;
    text-align: center;
    color: #444; }
  .page-nav ul li a {
    color: #444;
    font-size: .93rem;
    font-weight: 600; }

    @media (max-width: 768px) {
     .naol{

      width: 95%;
     }
  }
    @media (min-width: 769px) {
      .naol{

       width: 50%;
      }
      }
      .dd.hover{
background-color: rgb(226, 125, 125) !important;

      }
      .dd{background-color: #433e4e;}
      .imaf{
        left:0svi;
float: left;


      }
      .conf{

        float: right;
      }
      .blog-container {
        display: flex;
        align-items: flex-start;
    }
    .blog-image {
        flex: 1;
        margin-right: 20px;
    }
    .blog-details {
        flex: 2;
        align-items: center;
    }
    .blog-header {
        font-size: 24px;
        font-weight: bold;
    }
    .blog-date {
        color: #888;
    }
    .blog-content {
        
    }
    p{

      text-align: justify;
    }
    .ccc{

      height: 180px;
    }